import JoiBrowser from 'joi-browser';

const Validate = (values, schema) => {
  const options = {
    abortEarly: false,
    allowUnknown: true,
  };

  const { error } = JoiBrowser.validate(values, schema, options);

  const errors = { invalidValues: {} };
  if (error) {
    for (let item of error.details) {
      let [param, index] = item.path;
      const value = values[param];
      if (value || schema[param]['_flags']['presence'] === 'required') {
        if(index !== undefined && Number.isInteger(index)) {
          errors[`${param}_${index}`] = item.message;
          errors[param] = `an item from this list is invalid`
          let invalidValues = errors["invalidValues"][param];
          if(!invalidValues) {
            invalidValues = new Set([]);
          }
          invalidValues.add(values[param][index]);
          errors["invalidValues"][param] = invalidValues;
        } else {
          errors[param] = item.message;
          
          // the if block handling individual array entries will handle
          // setting the invalid values if the parameter is an array
          if(!Array.isArray(value)) {
              errors["invalidValues"][param] = value;
          }
        }
      }
    }
  }
  return errors;
};

export default Validate;

const JoiValidate = (values, schema) => {
  const options = {
    abortEarly: false,
    allowUnknown: true,
  };

  const { error } = schema.validate(values, options);

  const errors = {};
  if (error) {
    for (let item of error.details) {
      if(item.type === 'object.and') {
        for(let param of item.context.missing) {
          errors[param] = `required`;
        }
      } else {
        const paths = [...item.path]
        const lastKey = paths.pop();
        const lastError = paths.reduce((error, key) => error[key] = error[key] || {}, errors)
        lastError[lastKey] = item.message.replace(item.context.label, item.context.key);
      }
    }
  }
  return errors;
};

export { JoiValidate };